
import { getSdsxx, adminSdsxx } from '../../api/declare';
import { Options, Vue } from 'vue-class-component';
import { Provide, Watch, Emit } from 'vue-property-decorator';
import { ColumnProps } from 'ant-design-vue/es/table/interface';
import { Modal } from 'ant-design-vue';
import { h } from 'vue';
type Key = ColumnProps['key'];
interface declare {
  pageNum: number;
  pageSize: number;
  skssqq:string;
  skssqz:string;
  state: number,
}
@Options({
  components: {
  },
})

export default class DeclareManage extends Vue {
  @Provide() columns: any =[
  {
    title: '税款所属期起',
    dataIndex: 'skssqq',
  },
  {
    title: '税款所属期止',
    dataIndex: 'skssqz',
  },
  {
    title: '税率(速算)',
    dataIndex: 'sl',
  },
  {
    title: '申报人数',
    dataIndex: 'sbrs',
  },
  {
    title: '订单数',
    dataIndex: 'orderCount',
  },
  {
    title: '应税收入(元)',
    dataIndex: 'yssr',
  },
  {
    title: '应纳税额(元)',
    dataIndex: 'ynse',
  },
  {
    title: '累计已缴纳税额(元)',
    dataIndex: 'ljyjnse',
  },
  {
    title: '本期应补退税额(元)',
    dataIndex: 'bqybtse',
  },
  {
    title: '申报日期',
    dataIndex: 'sbrq',
  },
  {
    title: '申报状态',
    dataIndex: 'state',
    slots: { customRender: 'state' },
  },
  {
    title: '操作',
    dataIndex: "action",
    slots: { customRender: 'action' }, align: "center"
  }
];
//列表数据
data: any = [];
// 表单数据
form: {
  skssqq:string;
  skssqz:string,
  state: number;
} = {
  skssqq: '',
  skssqz: '',
  state: 0,
}
// 弹出框
visible: boolean = false
// 车主id
sbsids: any = ''
// 申报弹出框数据
@Emit()
/**
 * onChange类，日期
 * @constructor
 * @return {*}
 */
onChange(date: string, dateString: string): void {
  this.form.skssqq = dateString[0]
  this.form.skssqz = dateString[1]
}

onChange1(pageNumber: number) :void {
    this.data.pageNum = pageNumber
    // this.list()
    this.declareQuery()
}
 /**
 * declareQuery类，代扣代缴申报查询
 * @constructor
 * @return {*}
 */
 declareQuery(): void{
   const data: declare= {
      pageNum: this.data.pageNum,
      pageSize: 10,
      ...this.form
   }
  this.declareData(data)
 }
 /**
 * declareData类，代扣代缴申报列表接口
 * declareList接口参数限制
 * @constructor
 * @return {*}
 */
 declareData(data?: object): void {
   getSdsxx(data).then((res: any) => {
     this.data = res.data
   })
  }
 /**
 * showModal类，显示弹窗
 * @constructor
 * @return {*}
 */
  showModal(record: any): void {
    this.visible = true
    this.sbsids = record
  }
 /**
 * handleOk类，弹出框确定按钮
 * @constructor
 * @return {*}
 */
  handleOk(): void {
    this.visible = false;
    const data:any = {
      "id": this.sbsids.id,
    }
    adminSdsxx(data).then((res: any) => {
      if (res.code === 200 ) {
        if (res.data.status == 1) {
          Modal.success({
            content: h('p', '申报成功'),
            okText: '我知道了',
            class: 'aConfirm',
            centered: true,
          })
            setTimeout(()=>{
              location. reload()
            },500)
        } else {
           Modal.error({
            content: h('p', '申失败'),
            okText: '我知道了',
            class: 'aConfirm',
            centered: true,
          })
          setTimeout(()=>{
           location. reload()
        },500)
        }
      }
    })
  }
 /**
 * handleOk类，弹出框确定按钮
 * @constructor
 * @return {*}
 */
  failReason(text: string) {
    Modal.warning({
      content: text,
      okText: '我知道了',
      class: 'declareConfirm',
      centered: true,
    });
  }
  mounted(){
    this.declareData()
  }
}
